import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { GetPartReleaseRateDetailsResponseDef } from './get-part-release-rate-details.types';

export const getPartReleaseRateDetails = async () => {
  return axios
    .get<GetPartReleaseRateDetailsResponseDef>(
      `${getCoreApiUrl('part-release-rate')}`,
    )
    .then(({ data }) => {
      return data;
    });
};
