import { BarTooltipProps } from '@nivo/bar';
import { Typography } from 'gantri-components';
import { StyledTooltipStack } from '../../part-release-graph.styles';
import { Label } from '../../../../../../components/label';
import { jobStatuses } from '../../../../../../constants/options';
import { PartReleaseGraphDataItem } from '../../../../../../api/parts/routes/get-part-release-rate-details/get-part-release-rate-details.types';

export const PartReleaseRateTableTooltip = ({
  data,
}: BarTooltipProps<PartReleaseGraphDataItem>) => {
  const { id, jobs, value } = data;

  return (
    <StyledTooltipStack gap="x" horizontalPadding="2x" verticalPadding="x">
      <Label text={`${id}: ${value}`} />

      {jobs.map(({ status, value }) => {
        return (
          <Typography
            key={status}
            color={status === jobStatuses.failed ? 't2' : 't1'}
            text={`${status} jobs: ${value}`}
            variant="p3"
          />
        );
      })}
    </StyledTooltipStack>
  );
};
